<template>
  <div v-if="apiToken" class="vab-bg-surface-variant vap-page">
    <Loading
      class="vasion-loading-indicator"
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div class="vap-page-header">
      <h1>{{ folderName }}</h1>
    </div>
    <div class="vap-page-content">
      <div class="md-layout">
        <div>
          <md-menu
            md-align-trigger
            md-size="huge"
            :mdCloseOnSelect="true"
            :mdCloseOnClick="true"
            md-dense
          >
            <md-button
              id="btnActions"
              md-menu-trigger
              class="md-button md-theme-default md-default vasion-secondary-button"
              :disabled="isTopLevelFolder || !areThereCheckedDocuments || hasNoActions"
              @click="updateCheckedDocumentsAreTiffOrPDF()"
            >
              Actions
            </md-button>
            <md-menu-content class="vasion-menuDivHeight">
              <md-menu-item v-if="canAssignToWorkflow" @click="toggleStartWorkflow()">
                <div class="md-layout">
                  <div class="vasion-menuIcon">
                    <VasionWorkflowIcon2 />
                  </div>
                  <div class="vasion-menuText">
                    Start Workflow
                  </div>
                </div>
              </md-menu-item>
              <md-menu-item @click="viewDocumentsClick()">
                <div class="md-layout">
                  <div class="vasion-menuIcon">
                    <VasionMultipleIndex class="fill-grey-400" />
                  </div>
                  <div class="vasion-menuText">
                    View
                  </div>
                </div>
              </md-menu-item>
              <md-menu-item v-if="canDoFieldValidation" @click="goToFieldValidation()">
                <div class="md-layout">
                  <div class="vasion-menuIcon">
                    <VasionCheck class="fill-grey-400" />
                  </div>
                  <div class="vasion-menuText">
                    Field Validation
                  </div>
                </div>
              </md-menu-item>
              <md-menu-item v-if="canLegalHoldDocument" @click="legalHoldDocumentsClick()">
                <div class="md-layout">
                  <div class="vasion-menuIcon">
                    <VasionVaultIcon />
                  </div>
                  <div class="vasion-menuText">
                    Legal Hold
                  </div>
                </div>
              </md-menu-item>
              <md-menu-item v-if="canMoveDocument" @click="moveDocumentsClick()">
                <div class="md-layout">
                  <div class="vasion-menuIcon">
                    <VasionArrowRightIcon />
                  </div>
                  <div class="vasion-menuText">
                    Move Documents
                  </div>
                </div>
              </md-menu-item>
              <md-menu-item v-if="canCopyDocument" @click="copyDocumentsClick()">
                <div class="md-layout">
                  <div class="vasion-menuIcon">
                    <VasionFileCopyIcon />
                  </div>
                  <div class="vasion-menuText">
                    Copy Documents
                  </div>
                </div>
              </md-menu-item>
              <md-menu-item v-if="canEmailDocument" @click="emailDocumentsClick()">
                <div class="md-layout">
                  <div class="vasion-menuIcon">
                    <VasionEmailIcon />
                  </div>
                  <div class="vasion-menuText">
                    Email
                  </div>
                </div>
              </md-menu-item>
              <md-menu-item v-if="canDownloadDocument" @click="downloadSelectedDocuments()">
                <div class="md-layout">
                  <div class="vasion-menuIcon">
                    <VasionCloudDownloadIcon />
                  </div>
                  <div class="vasion-menuText">
                    Download
                  </div>
                </div>
              </md-menu-item>
              <md-menu-item v-if="canDeleteDocument || canRecycleDocument" @click="deleteDocumentsClick()">
                <div class="md-layout">
                  <div class="vasion-menuIcon">
                    <VasionDeleteIcon />
                  </div>
                  <div class="vasion-menuText">
                    {{ deleteDocumentText }}
                  </div>
                </div>
              </md-menu-item>
              <!-- <md-menu-item v-if="canAssignToWorkflow" @click="actionSelected()">
                <div class='md-layout'>
                  <div class='vasion-menuIcon'>
                    <VasionAddIcon />
                  </div>
                  <div class='vasion-menuText'>
                   Assign to Workflow
                  </div>
                </div>
              </md-menu-item> -->
              <md-menu-item v-if="canAccessThumbnailManager" @click="showThumbnailManager = true">
                <div class="md-layout">
                  <div class="vasion-menuIcon">
                    <VasionPhotoGallery />
                  </div>
                  <div class="vasion-menuText">
                    Thumbnail Manager
                  </div>
                </div>
              </md-menu-item>
              <md-menu-item v-if="canMergeDocuments" @click="showMergeDialog = !showMergeDialog">
                <div class="md-layout">
                  <div class="vasion-menuIcon">
                    <VasionArrowMerge />
                  </div>
                  <div class="vasion-menuText">
                    Merge Documents
                  </div>
                </div>
              </md-menu-item>
              <md-menu-item v-if="canSplitDocument && isThereOnlyOneCheckedDocument" @click="openSplitModal">
                <div class="md-layout">
                  <div class="vasion-menuIcon">
                    <VasionArrowSplit />
                  </div>
                  <div class="vasion-menuText">
                    Split Document
                  </div>
                </div>
              </md-menu-item>
              <md-menu-item v-if="canPrintDocument" @click="togglePrintDocument()">
                <div class="md-layout">
                  <div class="vasion-menuIcon">
                    <VasionPrintIcon />
                  </div>
                  <div class="vasion-menuText">
                    Print Documents
                  </div>
                </div>
              </md-menu-item>
              <md-menu-item v-if="canAccessOcr" @click="toggleOcr()">
                <div class="md-layout">
                  <div class="vasion-menuIcon">
                    <VasionMediaEditingScanDocumentIcon class="fill-grey-400" />
                  </div>
                  <div class="vasion-menuText">
                    OCR
                  </div>
                </div>
              </md-menu-item>
              <md-menu-item v-if="canBatesLabelDocument" @click="toggleBarcodeRecognition()">
                <div class="md-layout">
                  <div class="vasion-menuIcon">
                    <VasionBarcodeIcon class="fill-grey-400" />
                  </div>
                  <div class="vasion-menuText">
                    Barcode Recognition
                  </div>
                </div>
              </md-menu-item>
              <md-menu-item v-if="areDocumentsPdfOrTiffAndOnlyTwoOrThreeDocuments" @click="goToCompareDocuments()">
                <div class="md-layout">
                  <div class="vasion-menuIcon">
                    <VasionColumnViewIcon class="fill-grey-400" />
                  </div>
                  <div class="vasion-menuText">
                    Compare Documents
                  </div>
                </div>
              </md-menu-item>
              <md-menu-item v-if="canOCRDocuments" @click="toggleAIP()">
                <div class="md-layout">
                  <div class="vasion-menuIcon">
                    <VasionScheduledAip class="fill-grey-400" />
                  </div>
                  <div class="vasion-menuText">
                    AIP
                  </div>
                </div>
              </md-menu-item>
              <md-menu-item @click="openNewTab()">
                <div class="md-layout">
                  <div class="vasion-menuIcon">
                    <VasionOpenInNew class="fill-grey-400" />
                  </div>
                  <div class="vasion-menuText">
                    Open in New Browser Tab
                  </div>
                </div>
              </md-menu-item>
            </md-menu-content>
          </md-menu>
        </div>
        <div>
          <md-button
            id="btnRefresh"
            class="md-icon-button md-dense"
            title="Refresh"
            @click="refreshGrid()"
          >
            <VasionRefreshIcon />
          </md-button>
        </div>
        <div class="nav-controls">
          <div class="vasion-toolbar-divider" />
          <VasionPager
            :currentPageNumber="currentPageNumber"
            :totalPages="totalPages"
            @pageChanged="updateFolderData"
          />
          <div class="vasion-toolbar-divider" />
        </div>
        <div>
          <md-button
            id="btnUpOneFolder"
            class="md-icon-button md-dense"
            title="Go Up One Folder"
            :disabled="isTopLevelFolder"
            @click="goToParentFolder()"
          >
            <VasionVerticalAlignTopIcon :class="{ 'vasion-icon-disabled': isTopLevelFolder }" />
          </md-button>
          <md-button
            id="btnRootFolder"
            class="md-icon-button md-dense"
            title="Go To Root Folder"
            :disabled="isTopLevelFolder"
            @click="goToRootFolder()"
          >
            <VasionReplyAllIcon :class="{ 'vasion-icon-disabled': isTopLevelFolder }" />
          </md-button>
          <md-button
            v-if="canUploadDocument"
            id="btnCloudUpload"
            class="md-icon-button md-dense"
            title="Upload"
            :disabled="isTopLevelFolder"
            @click="uploadButtonClick()"
          >
            <VasionCloudUploadIcon :class="{ 'vasion-icon-disabled': isTopLevelFolder }" />
          </md-button>
          <md-button
            id="scan-to-vasion"
            class="md-icon-button md-dense"
            title="Scan to Vasion"
            :disabled="isTopLevelFolder"
            @click="openScanToVasion()"
          >
            <VasionScanIcon :class="{ 'vasion-icon-disabled': isTopLevelFolder }" />
          </md-button>
        </div>
        <div class="toolbar-counts">
          <span>Files: {{ fileCount }}  </span>
          <span>Folders: {{ folderCount }}</span>
        </div>
      </div>
      <div v-if="showGrid" class="secondary-table-container storage-table-container vasion-html-table-default">
        <VasionTable
          v-show="!isLoading"
          id="storage-table"
          ref="storageTable"
          :headerColumns="folderTableHeaders"
          :tableRows="folderTableRows"
          :hideColumns="hideColumns"
          :supportSorting="true"
          :documentIDIndex="1"
          @vasion-selection="handleRowSelect"
          @vasion-row-checked="handleRowCheckChange"
          @vasion-column-sort="gridColumnSort"
        />
      </div>
    </div>
    <md-dialog id="download-document-modal" :md-active.sync="showDownloadModal" :md-click-outside-to-close="false">
      <VasionDownloadOptions :multipleDocuments="multipleDocuments" :supportBurnAnnotations="!areThereCertifiedDocuments" @okClick="downloadDocument" @cancelClick="toggleDownloadDocument" />
    </md-dialog>

    <md-dialog id="download-document-modal" :md-active.sync="showPrintModal" :md-click-outside-to-close="false">
      <VasionPrintOptions :supportBurnAnnotations="!areThereCertifiedDocuments" @okClick="printSelectedDocuments" @cancelClick="togglePrintDocument" />
    </md-dialog>

    <md-dialog id="email-modal" :md-active.sync="showEmailModal" :md-click-outside-to-close="false" class="email-modal-div">
      <DocumentEmail
        :documentIDString="checkedDocumentsString"
        :supportBurnAnnotations="!areThereCertifiedDocuments"
        @email-result="showEmailSnackbar"
        @close="closeDocumentEmail"
      />
    </md-dialog>

    <md-dialog id="vasion-confirmation-div" :md-active.sync="showConfirmationDialog" :md-click-outside-to-close="false">
      <VasionConfirmationDialog :message="confirmationMessage" @noButtonClick="noConfirmationClick()" @yesButtonClick="yesConfirmationClick()" />
    </md-dialog>

    <md-dialog id="move-modal" :md-active.sync="showMoveModal" :md-click-outside-to-close="false" class="email-modal-div">
      <DocumentMove
        :documentIDString="checkedDocumentsString"
        @move-complete="moveCompleted"
        @close="showMoveModal = false"
      />
    </md-dialog>

    <md-dialog id="copy-modal" :md-active.sync="showCopyModal" :md-click-outside-to-close="false" class="email-modal-div">
      <DocumentCopy
        :documentIDArray="checkedDocumentsArray"
        @copy-complete="copyComplete(checkedDocumentsArray)"
        @close="showCopyModal = false"
      />
    </md-dialog>

    <VasionSnackbar
      id="results-snack"
      :showRawHtml="true"
      :showSnackbarBool.sync="showSnackbarBool"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />

    <VasionFileUploadDialog
      :allowMultiple="true"
      :serverOptions="serverOptions"
      :show.sync="showUploadDialog"
      @allFilesUploaded="allFilesUploaded"
    />

    <VasionGeneralModal
      :hideButtons="true"
      :rejectButtonText="'Cancel'"
      :confirmButtonText="'Merge'"
      :modalType="'slot'"
      :sync="showMergeDialog"
      @noButtonClick="closeMergeModal"
    >
      <MergeFiles
        @cancel="closeMergeModal"
        @browse="toggleMergeBrowse"
        @add="toggleMergeAdd"
        @showSnackbar="showSnackbar"
      />
    </VasionGeneralModal>

    <VasionGeneralModal
      id="split-document-modal"
      :modalType="'slot'"
      :hideButtons="true"
      :sync="showSplitModal"
      :modalMdContainer="true"
      @noButtonClick="closeSplitModal"
    >
      <SplitDocument
        :documentData="splitDocumentData"
        @cancel="closeSplitModal"
        @showSnackbar="showSnackbar"
      />
    </VasionGeneralModal>

    <VasionGeneralModal
      :hideButtons="true"
      :modalType="'slot'"
      :sync="showThumbnailManager"
      @noButtonClick="showThumbnailManager = false"
    >
      <ThumbnailManager v-if="showThumbnailManager" :documentId="checkedDocuments[0]" @cancel="closeThumbnailManager($event)" />
    </VasionGeneralModal>

    <md-dialog
      id="start-workflow-select-dialog"
      :md-click-outside-to-close="false"
      :md-active.sync="showWorkflowList"
    >
      <VasionSelectWorkflow
        :workflowList="workflowList"
        :showErrorLabel="showErrorLabel"
        @cancelButtonClick="showWorkflowList = !showWorkflowList"
        @continueButtonClick="continueWorkflowSelected"
      />
    </md-dialog>

    <md-dialog id="reassign-forward-modal" :md-active.sync="showUserSelectModal" :md-click-outside-to-close="false" class="email-modal-div">
      <VasionUserGroupSelect
        :modalTitle="'Select User'"
        :groupFilterId="userGroupAssignConfig.groupId"
        :userOnly="true"
        @noButtonClick="showUserSelectModal = !showUserSelectModal"
        @yesButtonClick="handleUserSelected"
      />
    </md-dialog>

    <VasionGeneralModal
      :rejectButtonText="'Cancel'"
      :confirmButtonText="'Run OCR'"
      modalTitle="OCR"
      :modalType="'slot'"
      :sync="showOcrDialog"
      @confirmButtonClick="ocrDocuments"
      @noButtonClick="showOcrDialog = !showOcrDialog"
    >
      <DocumentOcrPages @ocrAllPagesUpdated="updateOcrAllPages" />
    </VasionGeneralModal>

    <VasionGeneralModal
      :rejectButtonText="'Cancel'"
      :confirmButtonText="'Start'"
      modalTitle="Barcode Recognition"
      :modalType="'slot'"
      :sync="showBarcodeDialog"
      @confirmButtonClick="sendDocBarcodeRecognition"
      @noButtonClick="showBarcodeDialog = !showBarcodeDialog"
    >
      <DocumentBarcodeRecognition ref="barcodeRecognition" />
    </VasionGeneralModal>

    <VasionGeneralModal
      :rejectButtonText="'Cancel'"
      :confirmButtonText="'Run AIP'"
      modalTitle="Advanced Image Processing"
      :modalType="'slot'"
      :showTopRightCloseButton="true"
      :sync="showAIPDialog"
      @confirmButtonClick="runAIP"
      @noButtonClick="showAIPDialog = false"
    >
      <DocumentAIP ref="aip" />
    </VasionGeneralModal>

    <DocumentObjectPreview
      :show.sync="showDocumentObjectPreviewDialog"
      :documentIdList="uploadedDocuments" 
      :formId="defaultObject"
      @allDocumentsProcessed="allDocumentsProcessed"
    />

    <VasionGeneralModal
      id="delete-recycle-confirm-modal"
      :sync="showDeleteRecycleConfirmModal"
      :modalType="'slot'"
      :confirmButtonText="'Cancel'"
      :showRejectButton="true"
      :customButtonText="'Recycle'"
      :rejectButtonText="'Delete'"
      :customButtonDisabled="false"
      :showCustomButton="true"
      :modalTitle="'Recycle / Delete Documents'"
      :message="' Would you like to delete the document(s), or move them to the recycle bin?'"
      :showTitleAndMessage="true"
      @confirmButtonClick="showDeleteRecycleConfirmModal = !showDeleteRecycleConfirmModal"
      @customButtonClick="confirmRecycle"
      @noButtonClick="confirmDelete"
    />
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';

import DocumentAIP from '@/components/document/DocumentAIP.vue';
import DocumentBarcodeRecognition from '@/components/document/DocumentBarcodeRecognition.vue';
import DocumentCopy from '@/components/document/DocumentCopy.vue';
import DocumentEmail from '@/components/document/DocumentEmail.vue';
import DocumentMove from '@/components/document/DocumentMove.vue';
import MergeFiles from '@/components/document/MergeFiles.vue';
import SplitDocument from '@/components/document/SplitDocument.vue'
import ThumbnailManager from '@/components/document/ThumbnailManager.vue';
import DocumentOcrPages from '@/components/document/DocumentOcrPages.vue';
import DocumentObjectPreview from '@/components/document/DocumentObjectPreview.vue';

import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'
import { removeIllegalChars, testDownloadHasFileExtension } from '@/store/helperModules/common.module'

export default {
  name: 'VaultFolders',
  components: {
    DocumentAIP,
    DocumentBarcodeRecognition,
    DocumentCopy,
    DocumentEmail,
    DocumentMove,
    DocumentObjectPreview,
    DocumentOcrPages,
    Loading,
    MergeFiles,
    SplitDocument,
    ThumbnailManager,
   },
  data: function () {
    return {
      areThereCertifiedDocuments: false,
      canAccessMaxxSafeCurrentFolder: false,
      canAccessThumbnailManagerCurrentFolder: false,
      canAssignToWorkflowCurrentFolder: false,
      canCopyDocumentCurrentFolder: false,
      canDeleteDocumentCurrentFolder: false,
      canDownloadDocumentCurrentFolder: false,
      canEmailDocumentCurrentFolder: false,
      canLegalHoldCurrentFolder: false,
      canMoveDocumentCurrentFolder: false,
      canPrintCurrentFolder: false,
      canUploadToCurrentFolder: false,
      checkedDocuments: [],
      checkedDocumentsAreTiffOrPDF: false,
      confirmationMessage: '',
      confirmationType: '',
      currentPageNumber: 1,
      hideColumns: ['_Type_', '_TypeID_', 'StepID'],
      isLoading: false,
      loaderBackgroundColor,
      loaderColor,
      multipleDocuments: false,
      ocrAllPages: false,
      rootFoldersResponse: null,
      selectedWorkflowID: 0,
      serverOptions: { process: this.processHandler },
      showAIPDialog: false,
      showBarcodeDialog: false,
      showConfirmationDialog: false,
      showCopyModal: false,
      showDeleteRecycleConfirmModal: false,
      showDocumentObjectPreviewDialog: false,
      showDownloadModal: false,
      showPrintModal: false,
      showEmailModal: false,
      showErrorLabel: false,
      showSnackbarBool: false,
      showMergeDialog: false,
      showMoveModal: false,
      showOcrDialog: false,
      showThumbnailManager: false,
      showUploadDialog: false,
      showUserSelectModal: false,
      showSplitModal: false,
      showWorkflowList: false,
      snackbarImage: false,
      snackbarSubTitle: '',
      snackbarTitle: '',
      sortAscending: true,
      sortByColumn: '',
      splitDocumentData: {},
      startWorkflowPayload: {},
      subFolderName: '',
      uploadedDocuments: [],
      userGroupAssignConfig: {
        type: '',
        userOnly: false,
        groupId: 0,
      },
      workflowList: [],
    }
  },
  computed: {
    apiToken() { return this.$store.state.common.apiToken },
    areDocumentsPdfOrTiff() { return this.checkedDocumentsAreTiffOrPDF },
    areDocumentsPdfOrTiffAndOnlyTwoOrThreeDocuments() {
      return this.checkedDocumentsAreTiffOrPDF
      && (this.checkedDocumentsArray.length === 2 || this.checkedDocumentsArray.length === 3)
    },
    areThereCheckedDocuments() { return this.checkedDocuments.length > 0 },
    areThereMoreThenOneCheckedDocuments() { return this.checkedDocuments.length > 1 },
    canAccessMaxxSafe() { return this.$store.getters['common/canAccessMaxxSafe'] && this.canDownloadDocumentCurrentFolder },
    canAccessOcr() { return this.canOCRDocuments && this.checkedDocumentsAreTiffOrPDF },
    canAccessThumbnailManager() {
      return this.$store.getters['common/canAccessThumbnailManager']
        && this.canAccessThumbnailManagerCurrentFolder
        && !this.areThereMoreThenOneCheckedDocuments
        && this.checkedDocumentsAreTiffOrPDF
        && !this.areThereCertifiedDocuments
    },
    canAssignToWorkflow() { return this.$store.getters['common/canAssignToWorkflow'] && this.canAssignToWorkflowCurrentFolder },
    canBatesLabelDocument() { return this.$store.getters['common/canBatesLabelDocument'] && this.areDocumentsPdfOrTiff },
    canCopyDocument() { return this.$store.getters['common/canCopyDocument'] && this.canCopyDocumentCurrentFolder },
    canCreateSubfolder() { return this.$store.getters['common/canCreateSubfolder'] },
    canDeleteDocument() { return this.$store.getters['common/canDeleteDocument'] && this.canDeleteDocumentCurrentFolder },
    canDoFieldValidation() { return this.$store.getters['common/canViewDocumentFieldValidation'] },
    canDownloadDocument() { return this.$store.getters['common/canDownloadDocument'] && this.canDownloadDocumentCurrentFolder },
    canEmailDocument() { return this.$store.getters['common/canEmailDocument'] && this.canEmailDocumentCurrentFolder },
    canLegalHoldDocument() { return this.$store.getters['common/canLegalHold'] && this.canLegalHoldCurrentFolder },
    canMergeDocuments() { return this.$store.getters['common/canMergeDocuments'] && this.areThereMoreThenOneCheckedDocuments && !this.areThereCertifiedDocuments },
    canMoveDocument() { return this.$store.getters['common/canMoveDocument'] && this.canMoveDocumentCurrentFolder },
    canOCRDocuments() { return this.$store.getters['common/canOCRDocuments'] },
    canPrintDocument() { return this.$store.getters['common/canPrintDocument'] && this.canPrintCurrentFolder },
    canRecycleDocument() { return this.$store.getters['common/canRecycleDocument'] },
    canUploadDocument() { return this.$store.getters['common/canUploadDocument'] && this.canUploadToCurrentFolder },
    canSplitDocument() { return this.$store.getters['common/canSplitDocument'] && this.areDocumentsPdfOrTiff && !this.areThereCertifiedDocuments },
    checkedDocumentsArray() { return !this.checkedDocuments || this.checkedDocuments.length === 0 ? '' : Object.values(this.checkedDocuments) },
    checkedDocumentsString() { return !this.checkedDocuments || this.checkedDocuments.length === 0 ? '' : this.checkedDocuments.join(',') },
    checkIsLoading() { return this.$store.state.vault.isLoading },
    checkRefreshGrid() { return this.$store.state.vault.refreshGrid },
    defaultObject() { return this.$store.state.vault.defaultObject },
    deleteDocumentText() { return this.canDeleteDocument && this.canRecycleDocument ? "Delete / Recycle" : (this.canDeleteDocument ? "Delete" : "Recycle") },
    fileCount() { return this.$store.state.vault.fileCount },
    folderCount() { return this.$store.state.vault.folderCount },
    folderCurrentPage() { return !this.$store.state.vault.folderCurrentPage ? 1 : this.$store.state.vault.folderCurrentPage },
    folderID: {
      get: function () { return this.$store.state.vault.activeFolderId ? this.$store.state.vault.activeFolderId : 0 },
      set: function (newVal) {
        this.$store.dispatch('vault/setActiveFolderId', newVal)
      },
    },
    folderName() {
      if (this.$store.state.vault.folderDisplayName) {
        return this.$store.state.vault.folderDisplayName
      }
      return 'Storage'
    },
    folderTableHeaders() {
      if (!this.$store.state.vault.folderGridData || !this.$store.state.vault.folderGridData.tableHeaders) {
        return []
      }

      return this.$store.state.vault.folderGridData.tableHeaders
    },
    folderTableRows() {
      if (!this.$store.state.vault.folderGridData
      || !this.$store.state.vault.folderGridData.tableRows
      || !this.$store.state.vault.folderGridData.tableHeaders) {
        return []
      }
      return [...this.$store.state.vault.folderGridData.tableRows]
    },
    hasNoActions() {
      return !this.canLegalHoldCurrentFolder && !this.canMoveDocumentCurrentFolder && !this.canCopyDocumentCurrentFolder && !this.canEmailDocumentCurrentFolder
      && !this.canDownloadDocumentCurrentFolder && !this.canDeleteDocumentCurrentFolder && !this.canPrintCurrentFolder
    },
    isAdminUser() { return this.$store.getters['common/isAdminUser'] },
    isThereOnlyOneCheckedDocument() { return this.checkedDocuments.length === 1 },
    isTopLevelFolder() { return typeof this.folderID === 'undefined' || this.folderID <= 0 },
    mainNavExpanded() { return this.$store.state.mainViews.mainNavExpanded },
    pageSize() { return this.$store.state.common.pageSize },
    selectedStorageConfigId() { return this.$store.state.common.selectedStorageConfigId },
    showGrid() { return this.$store.state.vault.folderGridData && this.$store.state.vault.folderGridData.tableHeaders },
    storageID: {
      get: function () { return this.$store.state.vault.activeStorageId ? this.$store.state.vault.activeStorageId : 0 },
      set: function (newVal) {
        this.$store.dispatch('setActiveStorageId', newVal)
      },
    },
    tempCheckedDocuments() { return this.$store.state.document.tempCheckedDocuments },
    tempSortedRows() { return this.$store.state.document.tempSortedRows },
    totalPages() { return !this.$store.state.vault.folderTotalPages ? 1 : this.$store.state.vault.folderTotalPages },
    userId() { return this.$store.state.common.userID },
    vaultListData() { return this.$store.state.vault.vaultList },
  },
  watch: {
    checkIsLoading: function () { this.isLoading = this.checkIsLoading },
    checkRefreshGrid: function () {
      if (this.checkRefreshGrid) {
        this.refreshGrid()
      }
      this.$store.dispatch('vault/setRefreshGrid', false)
    },
    folderCurrentPage: function () { this.currentPageNumber = this.folderCurrentPage },
    folderID: function () { this.resetButtonSecurity() },
  },
  async created() {
    if (this.$route && this.$route.query && this.$route.query.redirect && this.$route.query.redirect !== '') {
      this.$router.push({ path: this.$route.query.redirect })
      return
    }
    this.isLoading = true
    this.clearAllButtonSecurity()

    if (this.$store.state.vault.activeFolderId > 0) {
      await this.setFolderID(this.$store.state.vault.activeFolderId, false)
    }

    if (this.$route.params.folderId) {
      const canAccessFolder = await this.$store.dispatch('vault/canAccessFolder', { Value: this.$route.params.folderId })
      if (canAccessFolder.Value === 'True') {
        await this.setFolderID(this.$route.params.folderId, false)
      } else {
        this.setFolderID(0, false)
        return
      }
    }

    this.folderID = this.$store.state.vault.vaultFoldersPayload.FolderId
    if (this.tempSortedRows[0] === this.folderID) {
      this.$refs.storageTable.orderByParams.indexValue = this.tempSortedRows[1].columnName
      this.$refs.storageTable.orderByParams.order = this.tempSortedRows[1].ascending ? 'asc' : 'desc'
      this.gridColumnSort(this.tempSortedRows[1])
    } else this.$store.dispatch('document/setTempSortedRows', [])

    this.sortAscending = this.$store.state.vault.vaultFoldersPayload.Ascending
    this.sortByColumn = this.$store.state.vault.vaultFoldersPayload.OrderBy
    this.resetButtonSecurity()

    // Auto checks the rows that are checked in the store
    if (this.tempCheckedDocuments[0] === this.folderID) {
      if(this.tempCheckedDocuments <= 2){
        this.checkedDocuments = this.tempCheckedDocuments[1] ? this.tempCheckedDocuments[1] : []
        this.folderTableRows.forEach(row => {
          if (this.checkedDocuments.includes(row.Values[1])) row.Values[2].value = true
        })
      }
    } else {
      this.$store.dispatch('document/setTempCheckedDocuments', [])
    }

    if (this.$route.params.previousPage && this.currentPageNumber === 1) {
      this.currentPageNumber = this.$route.params.previousPage
      this.updateFolderData(this.currentPageNumber)
    }

    if (this.$route.query.folderId) {
      const folderId = parseInt(this.$route.query.folderId) ? parseInt(this.$route.query.folderId) : 0
      const page = parseInt(this.$route.query.page) ? parseInt(this.$route.query.page) : this.currentPageNumber
      const pageSize = parseInt(this.$route.query.pageSize) ? parseInt(this.$route.query.pageSize) : this.pageSize

      await this.$store.dispatch('common/setPageSize', pageSize)
      this.currentPageNumber = parseInt(page)

      const payload = {
        FolderId: folderId,
        Skip: (page - 1) * pageSize,
        Take: pageSize,
        OrderBy: this.sortByColumn,
        Ascending: this.sortAscending,
      }

      await this.$store.dispatch('vault/getFolderContents', payload)
    }

    this.isLoading = false
  },
  methods: {
    allDocumentsProcessed() {
      this.uploadedDocuments = []
      this.showDocumentObjectPreviewDialog = false
      this.refreshGrid()
    },
    async allFilesUploaded() {
      this.refreshGrid()
      this.showUploadDialog = false

      if (this.uploadedDocuments?.length > 0) {
        await this.$amplitude.trackEvent('Document Added', {
          'source': 'Upload',
          'exportLocation': 'Storage',
        })
      }
      if (this.defaultObject > 0 && this.uploadedDocuments?.length > 0) {
        this.showDocumentObjectPreviewDialog = true
      }
    },
    clearAllButtonSecurity() {
      this.canAccessMaxxSafeCurrentFolder = false
      this.canAccessThumbnailManagerCurrentFolder = false
      this.canAssignToWorkflowCurrentFolder = false
      this.canCopyDocumentCurrentFolder = false
      this.canDeleteDocumentCurrentFolder = false
      this.canDownloadDocumentCurrentFolder = false
      this.canEmailDocumentCurrentFolder = false
      this.canPrintCurrentFolder = false
      this.canMoveDocumentCurrentFolder = false
      this.canLegalHoldCurrentFolder = false
      this.canUploadToCurrentFolder = false
    },
    closeDocumentEmail() { this.showEmailModal = false },
    closeMergeModal() {
      this.showMergeDialog = false;
      this.$store.dispatch('document/setMergeClearValues')
    },
    closeSplitModal() {
      this.splitDocumentData = {}
      this.showSplitModal = false
    },
    closeThumbnailManager(event) {
      if (event) {
        if (event.Value !== '') {
          this.showSnackbarBool = true
          this.snackbarImage = false
          this.snackbarSubTitle = event.Value
          this.snackbarTitle = 'Error Saving Thumbnail Manager changes'
        } else {
          this.showSnackbarBool = true
          this.snackbarImage = true
          this.snackbarTitle = 'Saved Thumbnail Manager changes'
          this.snackbarSubTitle = ''
          this.refreshGrid()
        }
      }
      this.showThumbnailManager = false
    },
    async confirmDelete() {
      this.showDeleteRecycleConfirmModal = false
      this.deleteSelectedDocuments(false)
    },
    async confirmRecycle() {
      this.showDeleteRecycleConfirmModal = false
      this.deleteSelectedDocuments(true)
    },
    async continueWorkflowSelected(workflowID) {
      this.selectedWorkflowID = workflowID

      if (this.selectedWorkflowID <= 0) {
        this.showWorkflowList = false
        return
      }
      this.startWorkflowForSelectedDocuments()
    },
    copyComplete(copyResultMessage) {
      this.snackbarTitle = copyResultMessage.length > 0 ? 'Document(s) successfully copied!' : 'Unable to copy one or more Documents'
      this.snackbarImage = copyResultMessage.length > 0
      this.showSnackbarBool = true
      this.showCopyModal = false
      this.refreshGrid()
    },
    copyDocumentsClick() {
      if (this.checkedDocumentsString === '') {
        return
      }

      this.showCopyModal = true
    },
    deleteDocumentsClick() {
      if (this.checkedDocumentsString === '') {
        return
      }

      if (this.canDeleteDocument && this.canRecycleDocument) {
        this.showDeleteRecycleConfirmModal = true
      }
      else
      {
        this.confirmationMessage = 'Are you sure you want to ' + (this.canDeleteDocument ? 'delete' : 'recycle')  + ' the selected Document(s)?'
        this.confirmationType = this.canDeleteDocument ? 'Delete' : 'Recycle'
        this.showConfirmationDialog = true
      }
    },
    async deleteSelectedDocuments(forceRecycle) {
      this.isLoading = true
      const errorMessage = await this.$store.dispatch('document/deleteMultipleDocuments', { 
        'DocumentIdString': this.checkedDocumentsString, 
        'ForceRecycle': forceRecycle 
      })
      const deleteSuccess = errorMessage === ''

      if (deleteSuccess) {
        this.snackbarTitle = 'Success'
        this.snackbarSubTitle = 'The Documents have been ' + (forceRecycle || !this.canDeleteDocument ? 'moved to the recycle bin' : 'deleted')
        this.snackbarImage = true
      } else {
        this.snackbarTitle = 'Error ' +  (forceRecycle || !this.canDeleteDocument ? 'moving' : 'deleting') + ' Documents'
        this.snackbarSubTitle = errorMessage
        this.snackbarImage = false
      }

      this.isLoading = false
      this.showSnackbarBool = true;
      // we are refreshing on success or not - if user tried to delete multiple documents, one might have failed, but the rest were deleted
      this.refreshGrid()
    },
    async downloadDocument(options) {
      const downloadPayload = {
        T: this.checkedDocumentsString,
        BurnAnnotations: options.burnAnnotations,
        UserID: this.userId,
        IncludeWatermark: false,
        ExtractPages: options.extractPages,
        ConvertToPDF: options.convertToPDF,
        StreamPDF: '',
      }
      const downloadResult = await this.$store.dispatch('document/downloadVasionDocument', downloadPayload)
      if (downloadResult) {
        const fileData = await this.$store.dispatch('document/getDocumentDetails', { documentID: this.checkedDocuments[0] })
        const newFileName = await removeIllegalChars(fileData.DisplayName)

        // eslint-disable-next-line
        const fileDownload = require('js-file-download')
        let localFileExtension = ''
        if (this.multipleDocuments) {
          localFileExtension = '.zip'
          fileDownload(downloadResult, `VasionDownload${localFileExtension}`)
        } else if (downloadPayload.ConvertToPDF === true) {
          localFileExtension = '.pdf'
          fileDownload(downloadResult, `${newFileName}${localFileExtension}`)
        } else {
          const docInfoPayload = {
            DocumentId: this.checkedDocuments[0],
            IncludeFileInfo: false,
            IncludeIndexForm: false,
            IncludeNotes: false,
            IncludeWorkflow: false,
            IncludeLinks: false,
            IncludeVersions: false,
          }

          const docInfoResult = await this.$store.dispatch('document/getDocumentDetailsWithPayload', docInfoPayload)
          if (docInfoResult && docInfoResult.DocumentName && docInfoResult.DocumentName.includes('.')) {
            localFileExtension = docInfoResult.DocumentName.substring(docInfoResult.DocumentName.lastIndexOf('.'))
          }

          testDownloadHasFileExtension(downloadResult, newFileName, localFileExtension)
        }
        await this.$amplitude.trackEvent('Document Shared', {'shareType': 'Download', 'app': 'Storage', })
      } else {
        this.snackbarTitle = 'Error Downloading Document(s)'
        this.snackbarSubTitle = 'Unable to download the selected Document(s)'
        this.snackbarImage = 'error-24-px.png';

        this.showSnackbarBool = true;
      }

      this.showDownloadModal = false
    },
    downloadSelectedDocuments() {
      this.multipleDocuments = false
      if (this.checkedDocuments.length < 1) {
        return
      }
      if (this.checkedDocuments.length > 1) {
        this.multipleDocuments = true
      }
      this.showDownloadModal = true
    },
    emailDocumentsClick() {
      if (this.checkedDocumentsString === '') {
        return
      }

      this.showEmailModal = true
    },
    goToCompareDocuments() {
      this.$store.dispatch('document/setBackRoute', '/storage/vaults')
      this.$router.push({ name: 'TheCompareDocuments', params: { documentIDs: this.checkedDocumentsArray } })
    },
    goToFieldValidation() {
      this.$store.dispatch('document/setBackRoute', '/storage/vaults')
      this.$router.push({ name: 'TheFieldValidationView', params: { folderID: this.folderID, documentID: this.checkedDocumentsArray[0], documentIDs: this.checkedDocumentsArray } })
    },
    async goToParentFolder() {
      this.isLoading = true

      if (this.folderID <= 0) {
        return
      }

      const parentFolderID = await this.$store.dispatch('vault/getParentFolderID', this.folderID)
      this.handleFolderSelected({
        value: typeof parentFolderID === 'undefined' || parentFolderID <= 0 ? 0 : parentFolderID,
        isSelected: true,
      })
    },
    async goToRootFolder() {
      this.isLoading = true

      this.folderID = await this.$store.dispatch('vault/getParentFolderID', this.folderID)
      this.handleFolderSelected({
        value: 0,
        isSelected: true,
      })
    },
    gridColumnSort(sortData) {
      this.sortByColumn = ''
      this.sortAscending = false

      if (sortData && sortData.columnName) {
        this.sortByColumn = sortData.columnName === 'Display Name' ? 'DisplayName' : sortData.columnName
        this.sortAscending = sortData.ascending
        this.$store.dispatch('document/setTempSortedRows', [this.folderID, sortData])
        this.refreshGrid()
      }
    },
    async handleFolderSelected(folder) {
      if (!folder || !folder.isSelected) {
        return
      }

      if (folder.value === 0) {
        const driveTypes = await this.$store.dispatch('storage/getConfiguredDriveTypes', { showOnlyDataAutomation: false })
        const rootDrive = driveTypes.find(drive => drive.storageConfigId == this.selectedStorageConfigId)
        if (rootDrive) {
          this.$store.dispatch('vault/setActiveFolderName', rootDrive.storageDisplayName)
        }
      } else {
        const payload = {
          parentFolderId: 0,
          enforceUserSecurity: false,
          groupByStorageType: false,
          storageConfigId: this.selectedStorageConfigId,
        }
        const response = await this.$store.dispatch('common/getChildFolders', payload)
        const searchId = parseInt(folder.value !== 0 ? folder.value : this.folderID, 10)
        response.data.FolderList.Values.forEach(item => {
          if (item.FolderId === searchId) {
            this.$store.dispatch('vault/setActiveFolderName', item.Name)
          }
        })
      }

      this.storageid = folder.storageid
      await this.setFolderID(parseInt(folder.value, 10), false)
      this.isLoading = false
    },
    handleRowCheckChange(newList) {
      this.folderTableRows.forEach(item => {
        if (!this.checkedDocuments.includes(item.Values[1]) && item.Values[2].show && item.Values[2].value) this.checkedDocuments.push(item.Values[1])
        else if (this.checkedDocuments.indexOf(item.Values[1]) >= 0 && item.Values[2].show && !item.Values[2].value) this.checkedDocuments.splice(this.checkedDocuments.indexOf(item.Values[1]), 1)
      })
      const checkedDocsForMerge = newList.map(item => {
        return { id: item.Values[1], name: item.Values[4] }
      })
      this.$store.dispatch('document/setTempCheckedDocuments', [this.folderID, this.checkedDocuments])
      this.$store.dispatch('document/setCheckedDocuments', checkedDocsForMerge)
    },
    async handleRowSelect(selectInfo) {
      if (!selectInfo.Values || selectInfo.Values.length <= 1 || selectInfo.Values[1] <= 0) {
        return
      }

      if (selectInfo.Values[0] === 0 || selectInfo.Values[0] === 4) {
        // it's a subfolder
        // eslint-disable-next-line
        // Sets folder title and updates table
        const columnNameIndex = this.$store.state.vault.folderGridData.tableHeaders.indexOf('Display Name')
        this.$store.dispatch('vault/setActiveFolderName', selectInfo.Values[columnNameIndex])
        await this.setFolderID(selectInfo.Values[1], true)
      } else if (selectInfo.Values[0] === 1) {
        // it's a Document
        // if the User clicks the "back" button in the document viewer, they will be taken back here
        this.$store.dispatch('document/setBackRoute', '/storage/vaults')
        this.$router.push({ name: 'Document', params: { documentID: selectInfo.Values[1], previousPage: this.currentPageNumber } })
      }
    },
    async handleUserSelected(user) {
      if (!user || !user.value) {
        this.showUserSelectModal = false
        return
      }
      this.startWorkflowPayload.documentIDList = this.checkedDocuments.filter(a => a)
      this.startWorkflowPayload.promptData.prmt_ChooseFromGroup.cfg_UserID = user.value

      this.startWorkflowForSelectedDocuments()
    },
    viewDocumentsClick(){
      this.multipleDocuments = false
      if (this.checkedDocuments.length < 1) {
        return
      }
      if (this.checkedDocuments.length == 1) {
        this.$store.dispatch('document/setBackRoute', '/storage/vaults')
        this.$router.push({ name: 'Document', params: { documentID: this.checkedDocuments[0], previousPage: this.currentPageNumber }})
        return
      }
      let firstDocument = this.checkedDocuments[0]
      this.checkedDocuments.shift()
      let remainingDocuments = this.checkedDocuments.join(',')
      this.$store.dispatch('document/setBackRoute', '/storage/vaults')
      this.$router.push({ name: 'DocumentMultiple', params: { documentID: firstDocument, additionalDocumentIds: remainingDocuments, previousPage: this.currentPageNumber} })
    },
    legalHoldDocumentsClick() {
      if (this.checkedDocumentsString === '') {
        return
      }

      this.confirmationMessage = 'Are you sure you want to place a legal hold on the selected Document(s)?'
      this.confirmationType = 'LegalHold'
      this.showConfirmationDialog = true
    },
    async legalHoldSelectedDocuments() {
      const errorMessage = await this.$store.dispatch('document/legalHoldDocuments', this.checkedDocumentsString)
      const deleteSuccess = errorMessage === ''

      if (deleteSuccess) {
        this.snackbarTitle = 'Documents legally held successfully'
        this.snackbarSubTitle = 'The Documents have been legally held'
        this.snackbarImage = 'check-circle-24-px.png';
      } else {
        this.snackbarTitle = 'Error legally holding Documents'
        this.snackbarSubTitle = errorMessage
        this.snackbarImage = 'error-24-px.png';
      }

      this.showSnackbarBool = true;

      this.refreshGrid()
    },
    moveCompleted(moveResultMessage) {
      this.snackbarTitle = !moveResultMessage ? 'Document(s) successfully moved!' : 'Unable to move one or more Documents'
      this.snackbarSubTitle = moveResultMessage
      this.snackbarImage = moveResultMessage
      this.showSnackbarBool = true
      this.showMoveModal = false
      this.refreshGrid()
    },
    moveDocumentsClick() {
      if (this.checkedDocumentsString === '') {
        return
      }

      this.showMoveModal = true
    },
    noConfirmationClick() { this.showConfirmationDialog = false },
    async ocrDocuments() {
      const payload = {
        documentIDs: this.checkedDocuments,
        ocrAllPages: this.ocrAllPages,
      }

      const response = await this.$store.dispatch('document/saveDocumentsForOCR', payload)
      this.toggleOcr()
      this.checkedDocuments = []

      if (response?.Value === 'True') {
        this.showSnackbarBool = true
        this.snackbarTitle = 'Success'
        this.snackbarSubTitle = 'An email will be sent when the documents have been OCRed.'
        this.snackbarImage = true
      } else {
         this.showSnackbarBool = true
        this.snackbarTitle = 'Error'
        this.message = 'Something went wrong, please contact admin'
        this.snackbarImage = false
      }
      this.refreshGrid()
    },
    async openNewTab() {
      if (this.checkedDocumentsString === '') {
        return
      }
      let popupsBlocked = false
      this.checkedDocuments.forEach(documentID => {
        const query = { folderId: this.folderID, pageSize: this.pageSize, page: this.currentPageNumber }
        const routeData = this.$router.resolve({ name: 'Document', params: { documentID: documentID }, query: query })
        const newTab = window.open(routeData.href, '_blank')
        if (newTab == null || typeof(newTab)=='undefined') {
          popupsBlocked = true
        } else {
          this.$amplitude.trackEvent('Document Viewed', {'docViewType': 'New Tab', 'app': 'Storage', })
        }
      });
      if (popupsBlocked) alert('Please disable your pop-up blocker and try again.')
    },
    async openScanToVasion() {
      const payload = {
        folderId: this.folderID,
        workflowId: 0,
      }
      await this.$store.dispatch('common/openScanToVasion', payload)
      await this.$amplitude.trackEvent('Document Added', {
          'source': 'Scan',
          'exportLocation': 'Unknown',
        })
    },
    async openSplitModal() {
      if (!this.canSplitDocument || !this.isThereOnlyOneCheckedDocument) return

      const documentID = this.checkedDocuments[0]
      const fileData = await this.$store.dispatch('document/getDocumentDetails', { documentID })

      if (fileData.FileDetail.PageCount <= 1) {
        this.showSnackbarBool = true
        this.snackbarImage = false
        this.snackbarTitle = 'Error'
        this.snackbarSubTitle = 'Documents with less than 2 pages cannot be split.'
        return
      }

      this.splitDocumentData = {
        displayName: fileData.DisplayName,
        id: fileData.DocumentId,
        pageCount: fileData.FileDetail.PageCount,
      }

      this.showSplitModal = true
    },
    async printSelectedDocuments(settings) {
      this.togglePrintDocument()
      const payload = {
        T: this.checkedDocumentsString,
        BurnAnnotations: settings.burnAnnotations,
        ConvertToPDF: true,
        UserID: this.userId,
      }
      const documentsForPrint = await this.$store.dispatch('workflow/downloadVasionDocumentsForPrint', payload)

      // eslint-disable-next-line
      const printJS = require('print-js')

      if (!documentsForPrint.Value) {
        this.showSnackbarBool = true
        this.snackbarTitle = 'Error!'
        this.snackbarSubTitle = 'The file(s) could not be printed.'
        this.snackbarImage = true
      } else if (documentsForPrint) {
        printJS({
          printable: documentsForPrint.Value, type: 'pdf', showModal: true, 
          onPrintDialogClose: () => {
            this.$store.dispatch('workflow/deletePrintedFile', documentsForPrint) 
            this.$amplitude.trackEvent('Document Shared', {'shareType': 'Print', 'app': 'Storage', })
          }, 
          onError: () => { 
            this.showSnackbarBool = true
            this.snackbarTitle = 'Error!'
            this.snackbarSubTitle = 'Error printing file, check "chrome://settings/content/pdfDocuments" and set to "Open PDFs in Chrome"'
            this.snackbarImage = false
          },
        });
      }
    },
    // eslint-disable-next-line
    processHandler: async function (fieldName, file, metadata, load, error, progress, abort) {
      const payload = {
        file: file,
        folderId: this.folderID,
      }
      try {
        const documentId = await this.$store.dispatch('storage/uploadFileToVasion', payload)
        this.uploadedDocuments.push(parseInt(documentId))
        progress(true, 0, 1024);
        load(file.name);
      } catch (e) {
        this.showSnackbarBool = true
        this.snackbarTitle = 'Error'
        this.snackbarSubTitle = 'Something went wrong while uploading the file'
        this.snackbarImage = false
        console.warn(e)
      }
    },
    refreshGrid() { this.updateFolderData(this.currentPageNumber) },
    async resetButtonSecurity() {
      if (this.folderID <= 0) {
        this.clearAllButtonSecurity()
        return
      }

      const folderSecurity = await this.$store.dispatch('vault/getReadOnlyFolderInfo', this.folderID)
      if (!folderSecurity) {
        this.clearAllButtonSecurity()
        return
      }

      // check if we can modify in this folder
      this.canUploadToCurrentFolder = folderSecurity.IsReadOnly === false || folderSecurity.CanModify === true
      this.canLegalHoldCurrentFolder = this.canUploadToCurrentFolder
      this.canMoveDocumentCurrentFolder = this.canUploadToCurrentFolder
      this.canAssignToWorkflowCurrentFolder = this.canUploadToCurrentFolder
      this.canAccessThumbnailManagerCurrentFolder = this.canUploadToCurrentFolder
      this.canCopyDocumentCurrentFolder = this.canUploadToCurrentFolder

      // both of these need export
      this.canDownloadDocumentCurrentFolder = folderSecurity.IsReadOnly === false || folderSecurity.CanExport === true
      this.canAccessMaxxSafeCurrentFolder = this.canDownloadDocumentCurrentFolder

      this.canEmailDocumentCurrentFolder = folderSecurity.IsReadOnly === false || folderSecurity.CanEmail === true
      this.canDeleteDocumentCurrentFolder = folderSecurity.IsReadOnly === false || folderSecurity.CanDelete === true
      this.canPrintCurrentFolder = folderSecurity.IsReadOnly === false || folderSecurity.CanPrint === true
    },
    async runAIP() {
      const aip = this.$refs.aip

      let validData = true
      if (aip.radio === 'send-to-folder' && (!aip.successFolderID || !aip.failureFolderID)) validData = false
      if (aip.radio === 'send-to-workflow' && !aip.selectedWorkflow.iID) validData = false
      if (!aip.selectedAIPIDs || aip.selectedAIPIDs.length === 0) validData = false
      aip.errorSuccessFolder = !aip.successFolderID
      aip.errorFailureFolder = !aip.failureFolderID
      aip.errorWorkflow = !aip.selectedWorkflow.iID
      aip.errorAIPs = !aip.selectedAIPIDs || aip.selectedAIPIDs.length === 0
      if (!validData) return

      const payload = {
        documentIDs: this.checkedDocumentsArray,
        aipIDs: aip.selectedAIPIDs,
        successFolderID: aip.radio === 'send-to-folder' ? aip.successFolderID : null,
        failureFolderID: aip.radio === 'send-to-folder' ? aip.failureFolderID : null,
        workflowID: aip.radio === 'send-to-workflow' ? aip.selectedWorkflow.iID : null,
        aipType: aip.lastSelectedRadioAIP === 'basic-aip' ? 'Basic' : 'Amazon Textract',
      }
      aip.isLoading = true
      const result = await this.$store.dispatch('document/saveBulkProcessActionAIP', payload)
      aip.isLoading = false

      if (result.Value === 'True') {
        this.snackbarTitle = 'AIP Created'
        this.snackbarSubTitle = 'Advanced Image Process successfully created!'
        this.snackbarImage = true
        this.showSnackbarBool = true
        this.showAIPDialog = false
        this.refreshGrid()
      } else {
        this.snackbarTitle = 'Error Creating AIP'
        this.snackbarSubTitle = 'There was an error creating your Advanced Image Process.\nPlease contact your administrator if the problem persists.'
        this.snackbarImage = false
        this.showSnackbarBool = true
      }
    },
    async sendDocBarcodeRecognition() {
      const payload = this.$refs.barcodeRecognition.buildPayload()
      payload.documentIDs = this.checkedDocuments

      const response = await this.$store.dispatch('document/saveDocumentsForBarcodeRecognition', payload)
      this.toggleBarcodeRecognition()
      this.checkedDocuments = []

      if (response?.Value === 'True') {
        this.showSnackbarBool = true
        this.snackbarTitle = 'Success'
        this.snackbarSubTitle = 'An email will be sent when the barcode process has completed.'
        this.snackbarImage = true
      } else {
         this.showSnackbarBool = true
        this.snackbarTitle = 'Error'
        this.message = 'Something went wrong, please contact admin'
        this.snackbarImage = false
      }
      this.refreshGrid()
    },
    async setFolderID(folderID, sync) {
      await this.$store.dispatch('vault/setActiveFolderId', folderID)
      this.sortAscending = true
      this.sortByColumn = ''
      if (sync) {
        this.$store.dispatch('vault/syncParentFolder', folderID)
      }

      await this.updateFolderData(1)
    },
    async showEmailSnackbar(value) {
      const [response] = value
      this.snackbarTitle = response ? 'Email successfully sent!' : 'Unable to send email';
      this.snackbarSubTitle = response ? 'Thank you for using Vasion\'s email service' : 'Please try again or contact your administrator';
      this.snackbarImage = response
      this.showSnackbarBool = true;
      await this.$amplitude.trackEvent('Document Shared', { 'shareType': 'Email', 'app': 'Storage', })

    },
    showSnackbar(messageObject) {
      if(messageObject.modalToClose) this[messageObject.modalToClose] = false
      this.snackbarTitle = messageObject.title
      this.snackbarSubTitle = messageObject.subTitle
      this.snackbarImage = messageObject.value
      this.showSnackbarBool = true
      if (messageObject.doNotRefreshGrid)  return
      this.refreshGrid()
    },
    async startWorkflowForSelectedDocuments() {
      this.showErrorLabel = false
      if (Object.keys(this.startWorkflowPayload).length === 0) {
        this.startWorkflowPayload = {
          documentIDList: this.checkedDocuments.filter(a => a),
          workflowId: this.selectedWorkflowID,
          userId: parseInt(this.userId, 10),
        }
      }
      const response = await this.$store.dispatch('workflow/attemptAssignToWorkflowWithData', this.startWorkflowPayload)
      if (response === null) {
        this.showWorkflowList = false
        this.checkedDocuments = []
        this.showUserSelectModal = false
        this.showSnackbarBool = true
        this.snackbarTitle = 'Success'
        this.snackbarSubTitle = 'Workflow has been started'
        this.snackbarImage = true
        this.refreshGrid()
      } else if (response?.userAssignPayload.retPromptKey === 'prmt_ChooseFromGroup') {
        this.userGroupAssignConfig.groupId = response.assignUserGroupFilterId
        this.startWorkflowPayload = response.userAssignPayload
        this.showWorkflowList = false
        this.showUserSelectModal = true
      } else {
        this.showSnackbarBool = true
        this.snackbarTitle = 'Error'
        this.snackbarSubTitle = 'There was an error starting the Workflow'
        this.snackbarImage = false
      }
    },
    toggleAIP() { this.showAIPDialog = !this.showAIPDialog },
    toggleBarcodeRecognition() { this.showBarcodeDialog = !this.showBarcodeDialog },
    toggleDownloadDocument() { this.showDownloadModal = !this.showDownloadModal },
    toggleMergeAdd() {
      this.showMergeDialog = !this.showMergeDialog
      this.showMergeAddDialog = !this.showMergeAddDialog
    },
    toggleMergeBrowse() {
      this.showMergeDialog = !this.showMergeDialog
      this.showMergeBrowseDialog = !this.showMergeBrowseDialog
    },
    toggleOcr() { this.showOcrDialog = !this.showOcrDialog },
    togglePrintDocument() {
      this.showPrintModal = !this.showPrintModal
    },
    async toggleStartWorkflow() {
      this.workflowList = await this.$store.dispatch('workflow/getWorkflowMenuItems')
      this.showWorkflowList = true
    },
    async updateFolderData(displayPageNumber) {
      this.isLoading = true
      if (displayPageNumber <= 0) {
        displayPageNumber = 1
      }

      const payload = {
        FolderId: 0,
        Skip: (displayPageNumber - 1) * this.pageSize,
        Take: this.pageSize,
        OrderBy: this.sortByColumn,
        Ascending: this.sortAscending,
      }

      if (!this.isTopLevelFolder) {
        payload.FolderId = this.folderID
        await this.$store.dispatch('vault/getFolderContents', payload)
      } else {
        await this.$store.dispatch('vault/getRootFolderContents', this.selectedStorageConfigId)
      }

      this.checkedDocuments = []
      this.isLoading = false
    },
    async updateCheckedDocumentsAreTiffOrPDF() {
      const documentFlags = await this.$store.dispatch('document/checkDocumentFlags', { Values: this.checkedDocuments })
      this.areThereCertifiedDocuments = documentFlags.documentsCertified

      const promiseArr = []
      this.checkedDocuments.forEach(docId => {
        const payload = { documentID: docId, fileExtensions: ['pdf', 'tif', 'tiff'] }
        promiseArr.push(this.$store.dispatch('document/checkFileExtension', payload))
      })
      const promises = await Promise.all(promiseArr)

      this.checkedDocumentsAreTiffOrPDF = !promises.includes('False')
    },
    updateOcrAllPages(value) { this.ocrAllPages = value },
    uploadButtonClick() { this.showUploadDialog = true },
    yesConfirmationClick() {
      this.showConfirmationDialog = false

      switch (this.confirmationType) {
        case 'Delete':
        case 'Recycle':
          this.deleteSelectedDocuments(false)
          break;
        case 'LegalHold':
          this.legalHoldSelectedDocuments()
          break;
        default:
          break
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

.email-modal-div {
  overflow: auto;
}

.nav-controls {
  display: flex;
}

@media(max-width: $phone){
  .vasion-toolbar-divider{
    display: none;
  }
}

.fill-grey-400 {
  fill: $grey-400;
}

.toolbar-counts {
  margin: auto 0px auto 10px;
  font-size: 16px;
}
::v-deep #split-document-modal .md-dialog {
  animation: unset;
  -webkit-animation: unset;
}
::v-deep #split-document-modal .root-div {
  padding: unset;
}
.storage-table-container {
  margin-top: 8px;
}
</style>
